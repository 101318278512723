// @ts-nocheck
/* eslint-enable */
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';

import AppActions from 'app/shared/flux/actions/AppActions';
import ListingEngineActions from 'app/shared/flux/actions/ListingEngineActions';

import { adapt_reduxToQuery } from 'app/shared/flux/actions/FilterActions/adapters';
import { areaUtils_getBestArea } from 'app/shared/utils/areaUtils';
import routeUtils from 'app/shared/utils/routeUtils';
import queryUtils from 'app/shared/utils/queryUtils';
import gmapUtils from 'app/client/utils/map/gmapUtils';

const RouteActions = {
    transitionToListing({ listingUri, keepMapLocation, filter } = {}) {
        return function(dispatch, getState) {
            const state = getState();
            const mapData = state.app.gmapLoaded ? gmapUtils.getMapData(window.map) : {};

            dispatch(ListingEngineActions.clearCurrentListing());
            const listingPath = dispatch(
                RouteActions.buildListingPath({
                    listingUri,
                    filter: filter ? filter : state.filter,
                    mapData: keepMapLocation ? mapData : null
                })
            );

            if (keepMapLocation) {
                dispatch(AppActions.setAppStoreBool('preserveAreaBoundary', true));
            } else {
                dispatch(AppActions.setAppStoreBool('preserveAreaBoundary', false));
            }

            window.router.transitionTo(listingPath);
        };
    },
    getAreaPath() {
        return function(dispatch, getState) {
            const state = getState();
            const area = areaUtils_getBestArea(state);

            const areaPath = dispatch(
                RouteActions.buildAreaPathWithQuery({
                    filter: state.filter,
                    areaResourceId: area.resourceId
                })
            );

            return areaPath;
        };
    },
    getAreaPathWithMapData() {
        return function(dispatch, getState) {
            const state = getState();
            const area = areaUtils_getBestArea(state);
            const query = getState().location.current.query || {};
            let mapData = state.app.gmapLoaded ? gmapUtils.getMapData(window.map) : {};
            const { lat, lon, z: zoom } = query;

            /**
             * On Mweb, this is a fallback when visiting an HDP from map view
             * because state.app.gmapLoaded will be false, so mapData will be empty.
             * On transition from map to HDP, URL will have map query params.
             * Clicking the X button on HDP takes user back to map, but strips
             * existing query params. This logic detects if they exist and
             * preserves the map state.
             */
            if (isEmpty(mapData) && lat && lon && zoom) {
                mapData = {
                    lat,
                    lon,
                    zoom
                };
            }

            const mapPath = dispatch(
                RouteActions.buildAreaPathWithQuery({
                    filter: state.filter,
                    areaResourceId: area.resourceId,
                    mapData
                })
            );

            return mapPath;
        };
    },
    transitionToAreaWithMapData() {
        return function(dispatch) {
            const mapPath = dispatch(RouteActions.getAreaPathWithMapData());
            window.router.transitionTo(mapPath);
        };
    },
    transitionToUrlWithFilterQuery(pathname) {
        return function(dispatch, getState) {
            // For a listing, use transitionToListing
            const state = getState();
            const border = state.location.current.query.border;
            const page = getState().location.current.query.page;
            const analytics = {
                HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
                HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP
            };
            const queryObj = adapt_reduxToQuery({
                filter: state.filter,
                border,
                page,
                analytics
            });

            /**
             * Remove pagination param, but keep all other filter params!
             * HPWEB-3514
             */
            const queryObjWithoutPageParam = omit(queryObj, 'page');

            window.router.transitionTo(pathname + queryUtils.stringify(queryObjWithoutPageParam));
        };
    },
    buildAreaPathWithQuery({ filter, areaResourceId, mapData }) {
        return function(dispatch, getState) {
            const state = getState();
            const border = state.location.current.query.border;
            const page = getState().location.current.query.page;
            const areaPath = routeUtils.buildAreaPath({
                areaResourceId: areaResourceId || 'new-york-ny',
                searchSlug: filter.search.slug
            });
            const analytics = {
                HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
                HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP
            };
            const queryObj = adapt_reduxToQuery({
                filter,
                mapData,
                border,
                page,
                analytics
            });

            return areaPath + queryUtils.stringify(queryObj);
        };
    },
    buildListingPath({ listingUri = '', filter = {}, mapData = {} }) {
        return function(dispatch, getState) {
            const border = getState().location.current.query.border;
            const page = getState().location.current.query.page;
            const analytics = {
                HPWEB_CONTROL: getState().location.current?.query?.HPWEB_CONTROL,
                HPWEB_EXP: getState().location.current?.query?.HPWEB_EXP
            };
            const queryObj = adapt_reduxToQuery({
                filter,
                mapData,
                border,
                page,
                analytics
            });

            return listingUri + queryUtils.stringify(queryObj);
        };
    },
    buildListingPathWithMap({ listingUri, keepMapLocation, filter }) {
        return function(dispatch, getState) {
            const state = getState();
            const mapData = state.app.gmapLoaded ? gmapUtils.getMapData(window.map) : {};

            const listingPath = dispatch(
                RouteActions.buildListingPath({
                    listingUri,
                    filter: filter ? filter : state.filter,
                    mapData: keepMapLocation ? mapData : null
                })
            );

            if (keepMapLocation) {
                dispatch(AppActions.setAppStoreBool('preserveAreaBoundary', true));
            } else {
                dispatch(AppActions.setAppStoreBool('preserveAreaBoundary', false));
            }

            return listingPath;
        };
    },
    updateUrlWithMapData(mapData) {
        return function(dispatch, getState) {
            const state = getState();
            const pathname = state.location.current.pathname;
            const currentResourceId = state.area.area.resourceId || 'new-york-ny';
            const currentSearchSlug = state.filter.search.slug || 'apartments-near-me';
            const currentArea = `/${currentResourceId}/${currentSearchSlug}`;
            const query = state.location.current.query;
            const isNearMeUrl = routeUtils.isNearMeUrl(pathname);

            delete query.page;
            const isMapDataDifferent = routeUtils.isMapDataDifferent({ data: mapData, q: query });

            if (isMapDataDifferent) {
                const newQueryObj = routeUtils.mergeMapDataIntoQuery({ data: mapData, q: query });
                const newQueryStr = queryUtils.stringify(newQueryObj);
                const newUrl = isNearMeUrl ? currentArea + newQueryStr : pathname + newQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else {
                return false;
            }
        };
    },
    updateUrlWithMapDataAndAreaResourceId({ areaResourceId, mapData }) {
        return function(dispatch, getState) {
            const pathname = getState().location.current.pathname;
            const query = getState().location.current.query;

            delete query.page;

            const currentAreaResourceId = routeUtils.getResourceIdFromUrl(pathname);
            const isAreaUrl = routeUtils.isAreaUrl(pathname);
            const shouldUpdateSearchSlug = areaResourceId && isAreaUrl && currentAreaResourceId !== areaResourceId;
            const shouldUpdateMapData = routeUtils.isMapDataDifferent({ data: mapData, q: query });

            if (shouldUpdateSearchSlug && shouldUpdateMapData) {
                const newPathname = pathname.replace(currentAreaResourceId, areaResourceId);
                const newQueryObj = routeUtils.mergeMapDataIntoQuery({ data: mapData, q: query });
                const newQueryStr = queryUtils.stringify(newQueryObj);
                const newUrl = newPathname + newQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else if (shouldUpdateSearchSlug) {
                const newPathname = pathname.replace(currentAreaResourceId, areaResourceId);
                const sameQueryStr = queryUtils.stringify(query);
                const newUrl = newPathname + sameQueryStr;
                window.router.transitionTo(newUrl);
                return true;
            } else if (shouldUpdateMapData) {
                return dispatch(RouteActions.updateUrlWithMapData(mapData));
            } else {
                return false;
            }
        };
    }
};

export default RouteActions;
