// @ts-nocheck
/* eslint-enable */
import api from 'app/shared/utils/api';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import BuildingV2 from 'app/shared/models/BuildingV2';
import constants from 'app/shared/constants/DispatchConstants';
import HomeHubCollection from 'app/shared/models/HomeHubCollection';

function getArea({ recentArea, userLocation }) {
    return isEmpty(recentArea) ? userLocation : recentArea;
}

function convertUserItemsResponse(apiResponse) {
    let building = get(apiResponse, 'data.buildings[0]');
    if (isEmpty(building)) {
        return false;
    }
    let buildingv2 = new BuildingV2(building);
    let result = find(buildingv2.units, (unit) => {
        return unit && unit.maloneLotIdEncoded;
    });

    return result;
}

// 5.1 Last viewed listing
export function lastViewed() {
    return function(dispatch) {
        return dispatch(api.user.item.get('viewed', 0, 1)).then((response) => {
            let listing = convertUserItemsResponse(response);
            if (isEmpty(listing)) {
                return false;
            }

            dispatch({
                type: constants.HOMEHUB_CONTINUE_WITH,
                payload: listing
            });
            return true;
        });
    };
}

// 6.1 Expand search to similar city
export function expandSearch({ recentArea, userLocation, limit = 5 } = {}) {
    return function(dispatch) {
        let area = getArea({ recentArea, userLocation });
        let areaResourceId = get(area, 'resourceId');
        let areaName = get(area, 'name');
        if (!areaResourceId) {
            return false;
        }

        const params = {
            type: 'city',
            areaResourceId,
            limit
        };

        // TODO: Could try seo/links/byResourceId
        return dispatch(api.area.nearby(params)).then((nearbyAreasResponse) => {
            let areas = get(nearbyAreasResponse, 'data.areas');
            if (isEmpty(areas)) {
                return false;
            }
            dispatch({
                type: constants.HOMEHUB_EXPAND_SEARCH,
                payload: { areas, areaName }
            });
            return true;
        });
    };
}

export function fetchCollectionData() {
    return function(dispatch) {
        return dispatch(api.homeHub()).then((results) => {
            const { data } = results;
            const googleAnalyticsPageView = [];
            let nonAmenityCollections = data.filter((collection) => {
                return collection.collectionType !== 'popularAmenities';
            });
            const collections = nonAmenityCollections.map((collection) => {
                // while we're processing each collection, get an array of collection typesl
                googleAnalyticsPageView.push(collection.collectionType);
                return new HomeHubCollection(collection);
            });
            return dispatch({
                type: constants.HOMEHUB_COLLECTIONS_LOADED,
                payload: {
                    collections,
                    googleAnalyticsPageView: googleAnalyticsPageView.join('|')
                }
            });
        });
    };
}
