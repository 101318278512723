// @ts-nocheck
/* eslint-enable */
import adapterUtils from 'app/shared/utils/adapterUtils';
import Filter from 'app/shared/models/Filter'; // Ensure this is a class with a proper constructor
// eslint-disable-next-line camelcase
import { filterUtils_getFilterLabels } from 'app/shared/utils/filterUtils';
import { adapt_reduxToQuery } from 'app/shared/flux/actions/FilterActions/adapters';
import queryUtils from 'app/shared/utils/queryUtils';
import routeUtils from 'app/shared/utils/routeUtils';

interface BuildingGroupDto {
    buildings: any[]; // Define the specific type if known
}

interface FilterProps {
    listingTypes?: string[];
    [key: string]: any; // To allow additional properties
}

interface HomeHubCollectionProps {
    collectionType?: string;
    buildingGroupDtos?: BuildingGroupDto;
    title?: string;
    filter?: FilterProps;
    resourceId?: string;
    numOfNewResults?: number;
    name?: string;
}

class HomeHubCollection {
    filter: typeof Filter;
    collectionType: string;
    title: string;
    numOfNewResults: number;
    listings: Record<string, any>;
    filterSummary: any;
    name: string;
    url: string;

    constructor({
        collectionType = '',
        buildingGroupDtos,
        title = '',
        filter = {},
        resourceId = '',
        numOfNewResults = 0,
        name = ''
    }: HomeHubCollectionProps = {}) {
        const { listingTypes } = filter;
        this.filter = new Filter(filter) as unknown as typeof Filter;
        this.collectionType = String(collectionType);
        this.title = String(title);
        this.numOfNewResults = Number(numOfNewResults);

        const processedListings = adapterUtils.apiLimitedBuildingArrayToSummaryArray(
            buildingGroupDtos?.buildings || [],
            this.filter
        );

        const listingsObj: Record<string, any> = {};
        processedListings.forEach((listing) => {
            listingsObj[listing.maloneLotIdEncoded] = listing;
        });
        this.listings = listingsObj;

        this.filterSummary = filterUtils_getFilterLabels(this.filter);
        this.name = String(name);

        if (listingTypes) {
            let searchSlug;
            if (this.collectionType === 'affordableApartments') {
                searchSlug = 'affordable-apartments-for-rent';
            } else if (this.collectionType === 'luxuryApartments') {
                searchSlug = 'luxury-apartments-for-rent';
            } else {
                searchSlug = 'apartments-for-rent';
            }
            const areaPath = routeUtils.buildAreaPath({ areaResourceId: resourceId, searchSlug });
            const queryObj = adapt_reduxToQuery({ filter: this.filter });
            const query = queryUtils.stringify(queryObj);
            this.url = `${areaPath}${query}`;
        } else {
            this.url = '';
        }
    }
}

export default HomeHubCollection;
